export default [
  {
    path: "/",
    name: "dashboard",
    component: () => import("@/views/pages/inside/Dashboard.vue"),
    meta: {
      pageTitle: "Dashboard",
      breadcrumb: [
        {
          text: "Dashboard",
          active: true,
        },
      ],
      resource: "Dashboard",
      action: "manage",
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/views/pages/inside/Dashboard.vue"),
    meta: {
      pageTitle: "Dashboard",
      breadcrumb: [
        {
          text: "Dashboard",
          active: true,
        },
      ],
      resource: "Dashboard",
      action: "manage",
    },
  },
  {
    path: "/on-demand-delivery",
    name: "on-demand-delivery",
    component: () =>
      import(
        "@/views/pages/inside/CreateDelivery/OnDemandDelivery/OnDemandDelivery.vue"
      ),
    meta: {
      pageTitle: "On Demand Delivery",
      breadcrumb: [
        {
          text: "On Demand Delivery",
          active: true,
        },
      ],
      resource: "On Demand Delivery",
      action: "manage",
    },
  },
  {
    path: "/on-demand-orders",
    name: "on-demand-orders",
    component: () =>
      import("@/views/pages/inside/CreateDelivery/OnDemandDelivery/Orders.vue"),
    meta: {
      pageTitle: "On Demand Order",
      breadcrumb: [
        {
          text: "On Demand Order",
          active: true,
        },
      ],
      resource: "On Demand Order",
      action: "manage",
    },
  },
  {
    path: "/on-demand-delivery/success",
    name: "on-demand-delivery-success",
    component: () =>
      import(
        "@/views/pages/inside/CreateDelivery/OnDemandDelivery/OrderSummary.vue"
      ),
    meta: {
      pageTitle: "On Demand Delivery > Success",
      breadcrumb: [
        {
          text: "On Demand Delivery > Success",
          active: true,
        },
      ],
      resource: "On Demand Delivery > Success",
      action: "manage",
    },
  },
  {
    path: "/Distributor-Bulk-delivery",
    name: "distributor-bulk-delivery",
    component: () =>
      import(
        "@/views/pages/inside/CreateDelivery/DistributorBulkDelivery/DistributorBulkDelivery.vue"
      ),
    meta: {
      pageTitle: "Distributor Bulk Delivery",
      breadcrumb: [
        {
          text: "Distributor Bulk Delivery",
          active: true,
        },
      ],
      resource: "Distributor Bulk Delivery",
      action: "manage",
    },
  },
  {
    path: "/Distributor-Bulk-delivery/cart",
    name: "Distributor-Bulk-delivery-cart",
    component: () =>
      import(
        "@/views/pages/inside/CreateDelivery/DistributorBulkDelivery/DistributorBulkCart.vue"
      ),
    meta: {
      pageTitle: "Distributor Bulk Delivery > Cart",
      breadcrumb: [
        {
          text: "Distributor Bulk Delivery > Cart",
          active: true,
        },
      ],
      resource: "Distributor Bulk Delivery > Cart",
      action: "manage",
    },
  },
  {
    path: "/Distributor-Bulk-delivery/paymentmethod",
    name: "Distributor-Bulk-delivery-paymentmethod",
    component: () =>
      import(
        "@/views/pages/inside/CreateDelivery/DistributorBulkDelivery/DistributorBulkPayment.vue"
      ),
    meta: {
      pageTitle: "Distributor Bulk Delivery > Payment Method",
      breadcrumb: [
        {
          text: "Distributor Bulk Delivery > Payment Method",
          active: true,
        },
      ],
      resource: "Distributor Bulk Delivery > Payment Method",
      action: "manage",
    },
  },
  {
    path: "/Distributor-Bulk-delivery/success",
    name: "Distributor-Bulk-delivery-success",
    component: () =>
      import(
        "@/views/pages/inside/CreateDelivery/DistributorBulkDelivery/OrderSummary.vue"
      ),
    meta: {
      pageTitle: "Distributor Bulk Delivery > Order Summary",
      breadcrumb: [
        {
          text: "Distributor Bulk Delivery > Order Summary",
          active: true,
        },
      ],
      resource: "Distributor Bulk Delivery > Order Summary",
      action: "manage",
    },
  },
  {
    path: "/Distributor-Bulk-Orders",
    name: "Distributor-Bulk-orders",
    component: () =>
      import(
        "@/views/pages/inside/CreateDelivery/DistributorBulkDelivery/Orders.vue"
      ),
    meta: {
      pageTitle: "Distributor Bulk Orders",
      breadcrumb: [
        {
          text: "Distributor Bulk Orders",
          active: true,
        },
      ],
      resource: "Distributor Bulk Orders",
      action: "manage",
    },
  },
  {
    path: "/DHL-delivery",
    name: "DHL-delivery",
    component: () =>
      import("@/views/pages/inside/CreateDelivery/DHLDelivery/DHLDelivery.vue"),
    meta: {
      pageTitle: "DHL Delivery",
      breadcrumb: [
        {
          text: "DHL Delivery",
          active: true,
        },
      ],
      resource: "DHL Delivery",
      action: "manage",
    },
  },
  {
    path: "/DHL-paymentmethod",
    name: "DHL-paymentmethod",
    component: () =>
      import("@/views/pages/inside/CreateDelivery/DHLDelivery/DHLPayment.vue"),
    meta: {
      pageTitle: "DHL Payment Method",
      breadcrumb: [
        {
          text: "DHL Payment Method",
          active: true,
        },
      ],
      resource: "DHL Payment Method",
      action: "manage",
    },
  },
  {
    path: "/DHL-Orders",
    name: "DHL-orders",
    component: () =>
      import("@/views/pages/inside/CreateDelivery/DHLDelivery/Orders.vue"),
    meta: {
      pageTitle: "DHL Orders",
      breadcrumb: [
        {
          text: "DHL Orders",
          active: true,
        },
      ],
      resource: "DHL Orders",
      action: "manage",
    },
  },
  {
    path: "/DHL-delivery/success",
    name: "DHL-delivery-success",
    component: () =>
      import(
        "@/views/pages/inside/CreateDelivery/DHLDelivery/OrderSummary.vue"
      ),
    meta: {
      pageTitle: "DHL Delivery > Success",
      breadcrumb: [
        {
          text: "DHL Delivery > Success",
          active: true,
        },
      ],
      resource: "DHL Delivery > Success",
      action: "manage",
    },
  },
  {
    path: "/log-points",
    name: "log-points",
    component: () => import("@/views/pages/inside/LogPoints/LogPoints.vue"),
    meta: {
      pageTitle: "Log Points",
      breadcrumb: [
        {
          text: "Log Points",
          active: true,
        },
      ],
      resource: "Log Points",
      action: "manage",
    },
  },
  {
    path: "/log-points-success",
    name: "log-point-success",
    component: () =>
      import("@/views/pages/inside/LogPoints/LogPointSummary.vue"),
    meta: {
      pageTitle: "Log Points",
      breadcrumb: [
        {
          text: "Log Points",
          active: true,
        },
      ],
    },
  },
  {
    path: "/log-point-details",
    name: "log-point-details",
    component: () =>
      import("@/views/pages/inside/LogPoints/LogPointDetails.vue"),
    meta: {
      pageTitle: "Log Points > Log Point Details",
      breadcrumb: [
        {
          text: "Log Points",
          active: true,
        },
      ],
    },
  },
  {
    path: "/phonebook",
    name: "phonebook",
    component: () => import("@/views/pages/inside/Phonebook/PhoneBook.vue"),
    meta: {
      pageTitle: "Phonebook",
      breadcrumb: [
        {
          text: "Phonebook",
          active: true,
        },
      ],
    },
  },
  {
    path: "/track-and-trace",
    name: "track-and-trace",
    component: () =>
      import("@/views/pages/inside/TrackAndTrace/TrackMain/TrackMain.vue"),
    meta: {
      pageTitle: "Track & Trace",
      breadcrumb: [
        {
          text: "Track & Trace",
          active: true,
        },
      ],
    },
  },
  {
    path: "/track-and-trace/order-tracking/:id",
    name: "track-order",
    component: () =>
      import("@/views/pages/inside/TrackAndTrace/TrackOrderID.vue"),
    meta: {
      pageTitle: "Track & Trace",
      breadcrumb: [
        {
          text: "Track & Trace",
          active: true,
        },
      ],
    },
  },
  {
    path: "/track-and-trace/delivery-tracking/:id",
    name: "track-delivery",
    component: () =>
      import("@/views/pages/inside/TrackAndTrace/TrackDeliveryID.vue"),
    meta: {
      pageTitle: "Track & Trace",
      breadcrumb: [
        {
          text: "Track & Trace",
          active: true,
        },
      ],
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/views/pages/inside/MyProfile.vue"),
    meta: {
      pageTitle: "My Profile",
      breadcrumb: [
        {
          text: "My Profile",
          active: true,
        },
      ],
    },
  },
];
