export default [
  {
    path: "/login",
    name: "auth-login",
    component: () => import("@/views/pages/auth/Login.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/signup",
    name: "auth-signup",
    component: () => import("@/views/pages/auth/SignUp.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/pages/miscellaneous/Error404.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
    },
  },
  {
    path: "*",
    redirect: "error-404",
    resource: "Auth",
  },
];
